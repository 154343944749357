import { template as template_c8e3dcfec9554be996e238077b4f0b88 } from "@ember/template-compiler";
const WelcomeHeader = template_c8e3dcfec9554be996e238077b4f0b88(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
