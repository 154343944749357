import { template as template_c07c2934fe6045dcbdb8418cd244fd57 } from "@ember/template-compiler";
const FKText = template_c07c2934fe6045dcbdb8418cd244fd57(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
