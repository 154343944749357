import { template as template_e88c4b76e0f847b5b911d7431d1b3e8a } from "@ember/template-compiler";
const FKLabel = template_e88c4b76e0f847b5b911d7431d1b3e8a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
