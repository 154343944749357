import { template as template_c4a49532c2a04bd9b22f5426fa5a7b8f } from "@ember/template-compiler";
const FKControlMenuContainer = template_c4a49532c2a04bd9b22f5426fa5a7b8f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
