import { template as template_ab5f59a1187b4ff0bb1798dedd101410 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_ab5f59a1187b4ff0bb1798dedd101410(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
